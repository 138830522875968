export default class menu {

	constructor(element) {

		this.windowScroll();

		element.addEventListener('click', () => {
			this.openCloseMenu(element);
			this.closeLightbox();
		});

		window.addEventListener('scroll', () => {
			this.windowScroll();
		});

	}

	windowScroll(){
		let wScroll = window.scrollY;
		let header = document.querySelector('.js-header');
		let book = document.querySelector('.header__book');

		if(wScroll <= 0){
			header.classList.remove('header--scrolled');
		}
		else{
			header.classList.add('header--scrolled');
		}
	}

	closeLightbox(){
		let modal = document.querySelector('.lightbox--active');

		if(modal){
			let wrap = modal.querySelector('.js-lightbox-wrap');
			wrap.classList.remove('lightbox__content--active');

			setTimeout(() => {
				modal.classList.remove('lightbox--anim');
				setTimeout(() => {
					modal.classList.remove('lightbox--active');
				}, 700);
			}, 700);

		}

	}

	openCloseMenu(element){

		let menu = document.querySelector('.js-menu');
		let wrap = document.querySelector('.js-menu-wrap');
		let items = document.querySelectorAll('.js-menu-item');
		let headerSocials = document.querySelector('.js-header-socials');
		let headerLangs = document.querySelector('.js-header-langs');
		let sign = document.querySelector('.js-header-sign');
		let header = document.querySelector('.js-header');

		element.classList.toggle('header__text--active');
		element.classList.add('header__text--off');

		if(element.classList.contains('header__text--active')){
			document.body.classList.add('hidden');
			document.querySelector('html').classList.add('hidden');
			menu.classList.add('menu--active');
			headerSocials.classList.add('socials--hide');
			headerLangs.classList.add('socials--hide');
			sign.classList.add('header__sign--hide');

			setTimeout(() => {
				menu.classList.add('menu--anim');
				header.classList.add('header--active');
			}, 50);

			setTimeout(() => {
				menu.classList.add('menu--color');
				header.classList.add('header--color');
			}, 500);

			setTimeout(() => {
				wrap.classList.add('menu__wrap--active');
				items.forEach((item, index) => {
					setTimeout(() => {
						item.classList.add('menu__item--active');
					}, index * 150);
				});
				element.classList.remove('header__text--off');
			}, 700);


		}
		else{
			document.body.classList.remove('hidden');
			document.querySelector('html').classList.remove('hidden');
			wrap.classList.remove('menu__wrap--active');
			setTimeout(() => {
				menu.classList.remove('menu--anim');
				headerSocials.classList.remove('socials--hide');
				headerLangs.classList.remove('socials--hide');
				sign.classList.remove('header__sign--hide');
				setTimeout(() => {
					menu.classList.remove('menu--active');
					element.classList.remove('header__text--off');
					items.forEach((item) => {
						item.classList.remove('menu__item--active');
					});
				}, 700);

				setTimeout(() => {
					menu.classList.remove('menu--color');
					header.classList.remove('header--color');
				}, 200);

				header.classList.remove('header--active');
			}, 700);

			

		}
	}

}
