export default class stepScroller {
	constructor(element){
        const stepScrollerDown = document.querySelectorAll('.js-step-scroller-down');
        const stepScrollerUp = document.querySelectorAll('.js-step-scroller-up');
        const dropdownItems = document.querySelectorAll('.js-dropdown-item');
        const stepInputs = document.querySelectorAll('.js-step-value');
        const step = 40;

        document.addEventListener('click', (e) => {
            const activeDropdown = document.querySelector('.additional-info__dropdown--show');
            if(activeDropdown){
                const rootParent = activeDropdown.parentNode;
                if(rootParent.contains(e.target)){
                    rootParent.classList.add('additional-info__single--up');
                }
                else{
                    rootParent.classList.remove('additional-info__single--up');
                    activeDropdown.classList.remove('additional-info__dropdown--show');
                    document.body.classList.remove('overlay');
                }
            }
        });

        stepInputs.forEach((input) => {
            input.addEventListener('click', () => {
                const parent = input.parentNode;
                const dropdown = parent.querySelector('.additional-info__dropdown');
                dropdown.classList.add('additional-info__dropdown--show');
                // document.body.classList.add('hidden');
                document.body.classList.add('overlay');
                // document.querySelector('html').classList.add('hidden');
            });
        });

        dropdownItems.forEach((item) => {
            item.addEventListener('click', () => {
                const parent = item.parentNode;
                const rootParent = parent.parentNode.parentNode;
                const input = rootParent.querySelector('.js-step-value');
                const activeDropdown = document.querySelector('.additional-info__dropdown--show');

                input.innerHTML = item.innerHTML;
                parent.querySelector('.additional-info__dropdown-item--active').classList.remove('additional-info__dropdown-item--active');
                item.classList.add('additional-info__dropdown-item--active');
                rootParent.classList.remove('additional-info__single--up');
                // document.body.classList.remove('hidden');
                document.body.classList.remove('overlay');
                // document.querySelector('html').classList.remove('hidden');
                if(activeDropdown){
                    activeDropdown.classList.remove('additional-info__dropdown--show');
                }

                if(rootParent.classList.contains('js-step-scroller-duration')){
                    const durationInput = document.querySelector('[name="duration"]');
                    durationInput.value = item.innerHTML;
                }

                if(rootParent.classList.contains('js-step-scroller-participants')){
                    const participantsInput = document.querySelector('[name="participants"]');
                    participantsInput.value = item.innerHTML;
                }
               
            });
        });

        stepScrollerDown.forEach((btn) => {
            btn.addEventListener('click', () => {
               const parent = btn.parentNode;
               const scroller = parent.querySelector('.additional-info__dropdown-scroll');
               const upBtn = parent.querySelector('.js-step-scroller-up');

               upBtn.classList.remove('additional-info__scroll-btn--hide');
               scroller.scrollBy(0, step);

                if(scroller.scrollTop >= scroller.scrollHeight - scroller.clientHeight){
                    btn.classList.add('additional-info__scroll-btn--hide');
                }
                else{
                    btn.classList.remove('additional-info__scroll-btn--hide');
                }
            });
        });

        stepScrollerUp.forEach((btn) => {
            btn.addEventListener('click', () => {
               const parent = btn.parentNode;
               const scroller = parent.querySelector('.additional-info__dropdown-scroll');
               const downBtn = parent.querySelector('.js-step-scroller-down');
               downBtn.classList.remove('additional-info__scroll-btn--hide');
               scroller.scrollBy(0, -step);
               
                if(scroller.scrollTop == 0){
                    btn.classList.add('additional-info__scroll-btn--hide');
                }
            });
        });
        
	}
}