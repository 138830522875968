import scrollMonitor from 'scrollmonitor';

export default class animations {
	constructor(){
        const texts = document.querySelectorAll('.text-anim');
        const images = document.querySelectorAll('.image-anim');
        const textsWatcher = [];
        let indexesInViewportTexts = 0;
        let indexesInViewportImages = 0;

        window.addEventListener('scroll', () => {
            indexesInViewportTexts = 0;
            indexesInViewportImages = 0;
		});

        texts.forEach((element, index) => {
            const elementWatcher = scrollMonitor.create(element);

            elementWatcher.enterViewport(() => {
                indexesInViewportTexts++;

                setTimeout(() => {
                    if(element.classList.contains('text-anim')){
                        element.classList.add('text-anim--show');
                    }
                }, indexesInViewportTexts * 300);
            });

            
        });
        

        images.forEach((element) => {
            const elementWatcher = scrollMonitor.create(element);

            elementWatcher.enterViewport(() => {
                indexesInViewportImages++;
                setTimeout(() => {
                    if(element.classList.contains('image-anim')){
                        element.classList.add('image-anim--show');
                    }
                }, indexesInViewportImages * 300);
            });
        });
	}
}