import Pikaday from 'pikaday';
import moment from 'moment';
import axios from 'axios';

export default class requestForm {
	constructor(element){

		const eventDateSelector = document.querySelector('.js-event-date');
		const modalEventDateSelector = document.querySelector('.form__row--calendar');

		if(eventDateSelector){
            this.eventDateCalendar();
            let picker = eventDateSelector.querySelector('.pika-single');
            picker.classList.add('pika-single--booking');
		}
		
		if(modalEventDateSelector){
			this.modalEventDateCalendar();
		}

		element.addEventListener('submit', (e) => {
			this.validateForm(element);
			e.preventDefault();
		});

		document.addEventListener('click', (e) => {
            if(eventDateSelector){
                let picker = eventDateSelector.querySelector('.pika-single');
                if(eventDateSelector.querySelector('.js-event-date-input').contains(e.target)){
					eventDateSelector.classList.add('additional-info__single--up');
                    // document.body.classList.add('hidden');
                    document.body.classList.add('overlay');
                    // document.querySelector('html').classList.add('hidden');
                    picker.classList.add('pika-single--active');
                }
                else{
                    if(picker.classList.contains('pika-single--active')){
						eventDateSelector.classList.remove('additional-info__single--up');
                        // document.body.classList.remove('hidden');
                        document.body.classList.remove('overlay');
                        // document.querySelector('html').classList.remove('hidden');
                        picker.classList.remove('pika-single--active');
                    }
                }
			}

			if(modalEventDateSelector){
                let picker = modalEventDateSelector.querySelector('.pika-single');
                if(modalEventDateSelector.querySelector('.js-date-field').contains(e.target)){
                    // document.body.classList.add('hidden');
                    document.body.classList.add('overlay');
                    // document.querySelector('html').classList.add('hidden');
                    picker.classList.add('pika-single--active');
                }
                else{
                    if(picker.classList.contains('pika-single--active')){
                        // document.body.classList.remove('hidden');
                        document.body.classList.remove('overlay');
                        // document.querySelector('html').classList.remove('hidden');
                        picker.classList.remove('pika-single--active');
                    }
                }
			}

        });
	}

	eventDateCalendar(){
        let field = document.querySelector('.js-event-date-input');
		let picker = new Pikaday({
			firstDay: 1,
			showDaysInNextAndPreviousMonths: true,
			enableSelectionDaysInNextAndPreviousMonths: true,
			i18n: {
			    previousMonth: 'prev',
			    nextMonth: 'next',
			    months: ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sept','Oct','Nov','Dec'],
			    weekdays: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
			    weekdaysShort: ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
			},
		    onSelect: function(date) {
				const inputInModal = document.querySelector('.js-date-field');
				field.innerHTML = moment(date).format('DD MMM YYYY');
				inputInModal.value = moment(date).format('DD MMM YYYY');
				document.querySelector('.pika-single--active').classList.remove('pika-single--active');
				document.body.classList.remove('overlay');
		    }
        });

        picker.setMinDate(new Date());
		field.parentNode.insertBefore(picker.el, field.nextSibling);
    }

	modalEventDateCalendar(){
		let field = document.querySelector('.js-date-field');
		let picker = new Pikaday({
			firstDay: 1,
			showDaysInNextAndPreviousMonths: true,
			enableSelectionDaysInNextAndPreviousMonths: true,
			i18n: {
			    previousMonth: 'prev',
			    nextMonth: 'next',
			    months: ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sept','Oct','Nov','Dec'],
			    weekdays: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
			    weekdaysShort: ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
			},
		    onSelect: function(date) {
				field.value = moment(date).format('DD MMM YYYY');
				document.querySelector('.pika-single--active').classList.remove('pika-single--active');
		    }
		});
		field.parentNode.insertBefore(picker.el, field.nextSibling);
		picker.setMinDate(new Date());
	}

	validateForm(element){
		let valid = true;
		let requiredFields = element.querySelectorAll('.js-required-field');
		requiredFields.forEach((field) => {
			if(!field.value){
				field.classList.add('form__input--error');
				valid = false;
			}
			else if(field.classList.contains('js-email-input')){
				valid = this.validateEmailType(field);
				if(!valid){
					field.classList.add('form__input--error');
				}
			}
			else if(field.classList.contains('js-phone-input')){
				valid = this.validatePhoneType(field);
				if(!valid){
					field.classList.add('form__input--error');
				}
			}


		});

		if(valid){
			this.sendFormData(element);
		}
	}

	validateEmailType(input){
		let emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		return emailRegex.test(input.value);
	}

	validatePhoneType(input){
		let phoneRegex = new RegExp(/^(?=.*[0-9])[- +()0-9]+$/);
		return phoneRegex.test(input.value);
	}

	sendFormData(element){
		const nameInput = element.querySelector('[name="name"]');
		const emailInput = element.querySelector('[name="email"]');
		const phoneInput = element.querySelector('[name="phone"]');
		const eventDateInput = element.querySelector('[name="event-date"]');
		const durationInput = element.querySelector('[name="duration"]');
		const participantsInput = element.querySelector('[name="participants"]');
		const messageInput = element.querySelector('[name="message"]');

		axios({
			method: 'post',
			url: '/endpoint/conferenceform',
			data: {
				name: nameInput.value,
				email: emailInput.value,
				phone: phoneInput.value,
				date: eventDateInput.value,
				duration: durationInput.value,
				participants: participantsInput.value,
				message: messageInput.value
			}
		})
		.then(function (response) {
			if(response.data == 'success'){
				const inputs = element.querySelectorAll('.form__input');
				const successModal = document.querySelector('[data-lightbox="success"]');
		
				if(successModal){
					let wrap = successModal.querySelector('.js-lightbox-wrap');
					successModal.classList.add('lightbox--active');

					setTimeout(() => {
						successModal.classList.add('lightbox--anim');
					}, 50);

					setTimeout(() => {
						wrap.classList.add('lightbox__content--active');
						document.querySelector('.lightbox__behind').classList.add('lightbox__behind--scale');
						if(inputs){
							inputs.forEach((input) => {
								input.value = '';
							});
						}

						setTimeout(() => {
							const lightboxs = document.querySelectorAll('.lightbox');
							const wraps = document.querySelectorAll('.js-lightbox-wrap');
							const header =  document.querySelector('.js-header');
							const sign = document.querySelector('.js-header-sign');
							const socials = document.querySelector('.js-header-socials');
							const openCloseBtn = document.querySelector('.js-open-close-menu');

							document.body.classList.remove('hidden');
							document.querySelector('html').classList.remove('hidden');
							header.classList.remove('header--active');
							sign.classList.remove('header__sign--hide');
							socials.classList.remove('socials--hide');
							openCloseBtn.classList.remove('header__text--active');

							wraps.forEach((wrap, index) => {
								wrap.classList.remove('lightbox__content--active');
								setTimeout(() => {
									lightboxs[index].classList.remove('lightbox--anim');
									setTimeout(() => {
										lightboxs[index].classList.remove('lightbox--active');
									}, 700);
								}, 700);
							});
							
							
						}, 5000);


					}, 700);
					
				}
			}
		})
		.catch(function (error) {
			console.log(error);
		});
	}
}