export default class lightbox {

	constructor(element) {
	
		element.addEventListener('click', () => {
			let lightbox = element.dataset.openlightbox;
			this.openLightbox(lightbox);
		});


	}

	openLightbox(lightbox){
		let modal = document.querySelector('[data-lightbox="'+lightbox+'"]');
		let wrap = modal.querySelector('.js-lightbox-wrap');
		let headerSocials = document.querySelector('.js-header-socials');
		let sign = document.querySelector('.js-header-sign');
		let menuBtn = document.querySelector('.js-open-close-menu');
		let header = document.querySelector('.js-header');
		let headerLangs = document.querySelector('.js-header-langs');
		
		menuBtn.classList.toggle('header__text--active');
		document.body.classList.add('hidden');
		document.querySelector('html').classList.add('hidden');
		headerSocials.classList.add('socials--hide');
		headerLangs.classList.add('socials--hide');
		sign.classList.add('header__sign--hide');
		modal.classList.add('lightbox--active');

		setTimeout(() => {
			modal.classList.add('lightbox--anim');
			header.classList.add('header--active');
		}, 50);

		setTimeout(() => {
			wrap.classList.add('lightbox__content--active');
		}, 700);
	}

}