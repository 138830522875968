import axios from 'axios';

export default class cookies {
	constructor(element) {
		element.addEventListener('click', () => {
			this.agreeCookies();
		});
	}

	agreeCookies(){

		axios({
			method: 'post',
			url: '/endpoint/cookies',
			data: {
				agree: true,
			}
		})
		.then((response) => {
			if(response.data == 'success'){
				let cookies = document.querySelector('.js-cookies');
				cookies.classList.add('cookies--hide');
				setTimeout(() => {
					cookies.remove();
				}, 350);
			}
		});
	}
}