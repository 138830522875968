export default class formInputs {
	constructor(element){

		let parent = element.parentNode;
		let rowLabel = parent.querySelector('.js-input-label');

		element.addEventListener('focus', () => {
			rowLabel.classList.add('form__row-label--active');
			element.classList.remove('form__input--error');
		});

		element.addEventListener('blur', () => {
			if(!element.value){
				rowLabel.classList.remove('form__row-label--active');
			}
		});

	}
}