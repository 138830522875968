import axios from 'axios';

export default class requestForm {
	constructor(element) {
		element.addEventListener('submit', (e) => {
			this.validateForm(element);
			e.preventDefault();
		});
	}

	validateForm(element){
		let valid = true;
		let requiredFields = element.querySelectorAll('.js-required-field');
		requiredFields.forEach((field) => {
			if(!field.value){
				field.classList.add('form__input--error');
				valid = false;
			}
			else if(field.classList.contains('js-email-input')){
				valid = this.validateEmailType(field);
				if(!valid){
					field.classList.add('form__input--error');
				}
			}
		});

		if(valid){
			this.sendFormData(element);
		}
	}

	validateEmailType(input){
		let emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		return emailRegex.test(input.value);
	}

	sendFormData(element){
		const nameInput = element.querySelector('[name="name"]');
		const emailInput = element.querySelector('[name="email"]');
		const messageInput = element.querySelector('[name="message"]');

		if(nameInput && emailInput && messageInput){

			axios({
				method: 'post',
				url: '/endpoint/messageform',
				data: {
					name: nameInput.value,
					email: emailInput.value,
					message: messageInput.value
				}
			})
			.then(function (response) {
				if(response.data == 'success'){
					const inputs = element.querySelectorAll('.form__input');
					const successModal = document.querySelector('[data-lightbox="success"]');
			
					if(successModal){
						let wrap = successModal.querySelector('.js-lightbox-wrap');
						successModal.classList.add('lightbox--active');

						setTimeout(() => {
							successModal.classList.add('lightbox--anim');
						}, 50);

						setTimeout(() => {
							wrap.classList.add('lightbox__content--active');
							document.querySelector('.lightbox__behind').classList.add('lightbox__behind--scale');
							if(inputs){
								inputs.forEach((input) => {
									input.value = '';
								});
							}

							setTimeout(() => {
								const lightboxs = document.querySelectorAll('.lightbox');
								const wraps = document.querySelectorAll('.js-lightbox-wrap');
								const header =  document.querySelector('.js-header');
								const sign = document.querySelector('.js-header-sign');
								const socials = document.querySelector('.js-header-socials');
								const openCloseBtn = document.querySelector('.js-open-close-menu');

								document.body.classList.remove('hidden');
								document.querySelector('html').classList.remove('hidden');
								header.classList.remove('header--active');
								sign.classList.remove('header__sign--hide');
								socials.classList.remove('socials--hide');
								openCloseBtn.classList.remove('header__text--active');

								wraps.forEach((wrap, index) => {
									wrap.classList.remove('lightbox__content--active');
									setTimeout(() => {
										lightboxs[index].classList.remove('lightbox--anim');
										setTimeout(() => {
											lightboxs[index].classList.remove('lightbox--active');
										}, 700);
									}, 700);
								});
								
								
							}, 5000);


						}, 700);
						
					}
				}
			})
			.catch(function (error) {
				console.log(error);
			});
		}
	}
}