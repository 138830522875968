import '../sass/style.scss';
import menu from './components/menu';
import bannerSlider from './components/bannerSlider';
import homeApartmentsSlider from './components/homeApartmentsSlider';
import listPageSlider from './components/listPageSlider';
import neighbourhood from './components/neighbourhood';
import formInputs from './components/formInputs';
import messageForm from './components/messageForm';
import requestForm from './components/requestForm';
import innerSlider from './components/innerSlider';
import lightbox from './components/lightbox';
import scrollDown from './components/scrollDown';
import cookies from './components/cookies';
import lightboxBehind from './components/lightboxbehind';
import booking from './components/booking';
import stepScroller from './components/stepScroller';
import animations from './components/animations';

const components = [
	{
		class: menu,
		selector: '.js-open-close-menu'
	},
	{
		class: bannerSlider,
		selector: '.js-banner-slider'
	},
	{
		class: homeApartmentsSlider,
		selector: '.js-apartments-slider'
	},
	{
		class: listPageSlider,
		selector: '.js-list-slider'
	},
	{
		class: neighbourhood,
		selector: '.js-neighbourhood-grid',
	},
	{
		class: formInputs,
		selector: '.js-form-input',
	},
	{
		class: messageForm,
		selector: '.js-message-form',
	},
	{
		class: requestForm,
		selector: '.js-request-form',
	},
	{
		class: innerSlider,
		selector: '.js-inner-slider',
	},
	{
		class: lightbox,
		selector: '[data-openlightbox]',
	},
	{
		class: scrollDown,
		selector: '.js-scroll-down',
	},
	{
		class: cookies,
		selector: '.js-agree-cookies',
	},
	{
		class: lightboxBehind,
		selector: '.lightbox__behind--onstart',
	},
	{
		class: booking,
		selector: '.js-room-booking',
	},
	{
		class: stepScroller,
		selector: '.js-step-scroller',
	},
	{
		class: animations,
		selector: 'body',
	}
];

components.forEach(component => {
    if (document.querySelector(component.selector) !== null) {
        document.querySelectorAll(component.selector).forEach(
            element => new component.class(element, component.options)
        )
    }
})

