import Pikaday from 'pikaday';
import moment from 'moment';
import smoothscroll from 'smoothscroll-polyfill';

export default class booking {
	constructor(element) {
        const arrivalDateSelector = document.querySelector('.js-arrival-date');
        const departureDateSelector = document.querySelector('.js-departure-date');
        const header = document.querySelector('.js-header');

        if(window.location.hash && window.location.hash == '#book'){
            smoothscroll.polyfill();
            window.scrollTo({ top: element.offsetTop - header.clientHeight , left: 0, behavior: 'smooth' });
        }

        if(arrivalDateSelector){
            this.arrivalInitCalendar(element);
            let picker = arrivalDateSelector.querySelector('.pika-single');
            picker.classList.add('pika-single--booking');
        }

        if(departureDateSelector){
            this.departureInitCalendar(element);
            let picker = departureDateSelector.querySelector('.pika-single');
            picker.classList.add('pika-single--booking');
        }

        document.addEventListener('click', (e) => {
            if(arrivalDateSelector){
                let picker = arrivalDateSelector.querySelector('.pika-single');
                if(arrivalDateSelector.querySelector('.js-arrival-date-input').contains(e.target)){
                    arrivalDateSelector.classList.add('additional-info__single--up');
                    // document.body.classList.add('hidden');
                    document.body.classList.add('overlay');
                    // document.querySelector('html').classList.add('hidden');
                    picker.classList.add('pika-single--active');
                }
                else{
                    if(picker.classList.contains('pika-single--active')){
                        arrivalDateSelector.classList.remove('additional-info__single--up');
                        // document.body.classList.remove('hidden');
                        document.body.classList.remove('overlay');
                        // document.querySelector('html').classList.remove('hidden');
                        picker.classList.remove('pika-single--active');
                    }
                }
            }

            if(departureDateSelector){
                let picker = departureDateSelector.querySelector('.pika-single');
                if(departureDateSelector.querySelector('.js-departure-date-input').contains(e.target)){
                    departureDateSelector.classList.add('additional-info__single--up');
                    // document.body.classList.add('hidden');
                    document.body.classList.add('overlay');
                    // document.querySelector('html').classList.add('hidden');
                    picker.classList.add('pika-single--active');
                }
                else{
                    if(picker.classList.contains('pika-single--active')){
                        departureDateSelector.classList.remove('additional-info__single--up');
                        // document.body.classList.remove('hidden');
                        document.body.classList.remove('overlay');
                        // document.querySelector('html').classList.remove('hidden');
                        picker.classList.remove('pika-single--active');
                    }
                }
            }
        });
    }
    
    arrivalInitCalendar(element){
        let field = element.querySelector('.js-arrival-date-input');
		let picker = new Pikaday({
			firstDay: 1,
			showDaysInNextAndPreviousMonths: true,
			enableSelectionDaysInNextAndPreviousMonths: true,
			i18n: {
			    previousMonth: 'prev',
			    nextMonth: 'next',
			    months: ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sept','Oct','Nov','Dec'],
			    weekdays: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
			    weekdaysShort: ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
			},
		    onSelect: function(date) {
                field.innerHTML = moment(date).format('DD MMM YYYY');
                document.body.classList.remove('overlay');
                document.querySelector('.pika-single--active').classList.remove('pika-single--active');
		    }
        });

        picker.setMinDate(new Date());
		field.parentNode.insertBefore(picker.el, field.nextSibling);
    }

    departureInitCalendar(element){
        let field = element.querySelector('.js-departure-date-input');
		let picker = new Pikaday({
			firstDay: 1,
			showDaysInNextAndPreviousMonths: true,
			enableSelectionDaysInNextAndPreviousMonths: true,
			i18n: {
			    previousMonth: 'prev',
			    nextMonth: 'next',
			    months: ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sept','Oct','Nov','Dec'],
			    weekdays: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
			    weekdaysShort: ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
			},
		    onSelect: function(date) {
                field.innerHTML = moment(date).format('DD MMM YYYY');
                document.body.classList.remove('overlay');
                document.querySelector('.pika-single--active').classList.remove('pika-single--active');
		    }
        });

        picker.setMinDate(new Date());
		field.parentNode.insertBefore(picker.el, field.nextSibling);
    }
}