import Swiper from 'Swiper';

export default class innerSlider {
	constructor(element) {
		this.initSlider(element);
	}

	initSlider(element){
		let mySwiper = new Swiper (element, {
        	loop: true,
			speed: 1000,
			simulateTouch: false,
			mousewheel: false,
			slidesPerView: 'auto',
      		centeredSlides: true,
			navigation: {
				nextEl: '.js-next-slide-inner',
				prevEl: '.js-prev-slide-inner',
			},
			effect: 'coverflow',
			coverflowEffect: {
				rotate: 0,
				stretch: -202,
				depth: 205,
				modifier: 1,
				slideShadows: false
			},
			on: {
				slideChangeTransitionStart: function () {
					let prevBtn = element.querySelectorAll('.js-prev-slide-inner');
					let nextBtn = element.querySelectorAll('.js-next-slide-inner');

					prevBtn.forEach((btn) => {
						btn.classList.add('arrows__single--hide-prev');
					});

					nextBtn.forEach((btn) => {
						btn.classList.add('arrows__single--hide-next');
					});


					setTimeout(function(){
						prevBtn.forEach((btn) => {
							btn.classList.remove('arrows__single--hide-prev');
						});

						nextBtn.forEach((btn) => {
							btn.classList.remove('arrows__single--hide-next');
						});
					}, 850);
				}
			}
		})
	}
}