import smoothscroll from 'smoothscroll-polyfill';

export default class scrollDown {
	constructor(element){
		smoothscroll.polyfill();
		element.addEventListener('click', () => {
			const nextSection = document.querySelector('.js-next-section');
			const header = document.querySelector('.js-header');

			if(nextSection && header){
				window.scrollTo({ top: nextSection.offsetTop - header.clientHeight, left: 0, behavior: 'smooth' });
			}
		});
	}
}