import Swiper from 'Swiper';

export default class bannerSlider {
	constructor(element) {
		if(window.innerWidth > 768){
			this.initSlider(element);
		}
	}

	initSlider(element){
		let mySwiper = new Swiper (element, {
			loop: true,
			speed: 1000,
			simulateTouch: false,
			mousewheel: false,
			navigation: {
				nextEl: '.js-next-slide-list',
				prevEl: '.js-prev-slide-list',
			},
			effect: 'fade',
			// coverflowEffect: {
			// 	rotate: 50,
			// 	stretch: -90,
			// 	depth: 100,
			// 	modifier: 1,
			// 	slideShadows: false
			// },
			on: {
				slideChangeTransitionStart: function () {
					let prevBtn = element.querySelector('.js-prev-slide-list');
					prevBtn.classList.add('arrows__single--hide-prev');

					let nextBtn = element.querySelector('.js-next-slide-list');
					nextBtn.classList.add('arrows__single--hide-next');

					setTimeout(function(){
						prevBtn.classList.remove('arrows__single--hide-prev');
					}, 850);

					setTimeout(function(){
						nextBtn.classList.remove('arrows__single--hide-next');
					}, 850);
				}
			}
		})
	}
}