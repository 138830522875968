
import Packery from 'Packery';
import axios from 'axios';

export default class neighbourhood {
	constructor(element) {
		this.initGrid(element);
		this.pckry;
		this.page = 1;
		this.activeCategory = document.querySelector('.neighbourhood__nav-single--active').dataset.categoryId;

		let buttons = document.querySelectorAll('.js-neighbourhood-nav-click');
		let loadMore = document.querySelector('.js-load-more');

		loadMore.addEventListener('click', () => {
			this.page++;
			this.loadPosts();
		});

		buttons.forEach((btn) => {
			btn.addEventListener('click', () => {
				this.navClick(btn);
			});
		});
	}

	navClick(btn){
		const activeBtn = document.querySelector('.neighbourhood__nav-single--active');
		const elements = document.querySelectorAll('.neighbourhood__grid-item');

		this.activeCategory = btn.dataset.categoryId;
		
		activeBtn.classList.remove('neighbourhood__nav-single--active');
		btn.classList.add('neighbourhood__nav-single--active');

		this.pckry.remove(elements);
		this.page = 1;
		this.loadPosts();
		
	}

	loadPosts(){
		const grid = document.querySelector('.js-neighbourhood-grid');
		const pckry = this.pckry;
		const page = this.page;
		const rootElement = document.querySelector('body');
		const lang = rootElement.dataset.currentLang;

		axios({
			method: 'get',
			url: '/endpoint/neighbourhood/'+this.activeCategory+'/'+lang+'/?page='+this.page,
		})
		.then(function (response) {
			if(response.data.data){
				const posts = response.data.data;
				posts.forEach((post) => {
					let innerElementHTML = '<div class="neighbourhood__grid-text"><h2 class="neighbourhood__grid-title">'+post.title+'</h2><div class="simple-text simple-text--neighbourhood-text"><p>'+post.text+'</p></div><a href="'+post.link+'" class="small-btn small-btn--explore small-btn--neighbourhood"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.449 18.424"><g transform="translate(-357.4 -747.575)"><path d="M6,13V6A6.018,6.018,0,0,0,0,0H-7" transform="translate(358 759) rotate(-90)"></path><path d="M2406.475,2179.7h7.072v-7.071" transform="translate(-2876.913 918.356) rotate(-45)"></path></g></svg>'+post.read_more+'</a></div>';
					let elementHTML = document.createElement('div');
					elementHTML.classList.add('neighbourhood__grid-item');
					if(post.type == 'big'){
						elementHTML.classList.add('neighbourhood__grid-item--bigger');
					}

					elementHTML.style.backgroundImage = "url('"+post.image+"')";

					elementHTML.innerHTML = innerElementHTML;
					grid.appendChild(elementHTML);

					grid.appendChild(elementHTML);
					pckry.appended(elementHTML);
					pckry.layout();

					if(response.data.last_page == page){
						document.querySelector('.js-load-more').classList.add('btn--neighbourhood-hide');
					}

				});
			}
		})
		.catch(function (error) {
			console.log(error);
		});
	}

	initGrid(element){
		this.pckry = new Packery(element, {
			itemSelector: '.neighbourhood__grid-item',
			gutter: '.neighbourhood__grid-spacing',
			percentPosition: true
		});
	}
}