import Swiper from 'Swiper';

export default class homeApartmentsSlider {
		constructor(element) {
		this.initSlider(element);
	}

	initSlider(element){
		let mySwiper = new Swiper (element, {
			loop: true,
			speed: 1500,
			simulateTouch: false,
			mousewheel: false,
			slidesPerView: 'auto',
      		centeredSlides: true,
			navigation: {
				nextEl: '.js-next-slide-apartments',
				prevEl: '.js-prev-slide-apartments',
			},
			effect: 'coverflow',
			coverflowEffect: {
				rotate: 0,
				stretch: -1000,
				depth: 500,
				modifier: 1,
				slideShadows: false
			},
			breakpoints: {
				768: {
					coverflowEffect: {
						rotate: 0,
						stretch: -200,
						depth: 500,
						modifier: 1,
						slideShadows: false
					},
				}
			},
			on: {
				slideChangeTransitionStart: function () {
					let prevBtn = element.querySelectorAll('.js-prev-slide-apartments');
					let nextBtn = element.querySelectorAll('.js-next-slide-apartments');

					prevBtn.forEach((btn) => {
						btn.classList.add('arrows__single--hide-prev');
					});

					nextBtn.forEach((btn) => {
						btn.classList.add('arrows__single--hide-next');
					});


					setTimeout(function(){
						prevBtn.forEach((btn) => {
							btn.classList.remove('arrows__single--hide-prev');
						});

						nextBtn.forEach((btn) => {
							btn.classList.remove('arrows__single--hide-next');
						});
					}, 850);
				}
			}
		})
	}
}